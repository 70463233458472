<!--
 * @Description: 已收货 - 上传付款单
 * @Author: zhang zhen
 * @Date: 2023-05-30 16:12:57
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-02-03 18:54:16
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/UploadPayedFile.vue
-->
<template>
    <DrawerView :visible="visible" :width="800" title="上传付款单" @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model :colon="false">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="应付金额" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label-align="left"><a-tag color="#2db7f5">{{ payInfo.payables || '0' }}</a-tag> 元</a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="已付金额" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label-align="left"><a-tag color="#87d068">{{ payInfo.paid || '0' }}</a-tag> 元</a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="上传单据" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label-align="left" required>
        <div class="uploadCover">
          <div style="width: 334px">
            <UploadDrag v-model="uploadFiles" @change="handleChangeFile" :number="1" />
          </div>
          <div class="endTitle">可支持多种文件格式，如PDF，XLS、PNG、JPG等</div>
        </div>
      </a-form-model-item>
      
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from '@/components/plugins/uploadDrag'
import { postAction, getAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
export default {
  name: 'UploadPayedFile',
  components: {
    UploadDrag,
    DrawerView
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      uploadFiles: [],
      payInfo: {},
      newOrderNo: null
    }
  },
  methods: {
    handleOpen(orderNo) {
      this.visible = true
      this.newOrderNo = orderNo;
      getAction('/order/pay/get', {
        orderNo: orderNo || this.newOrderNo
      }).then(res => {
        const { success, message, data } = res
        if (success) {
         this.payInfo = data
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleSubmit() {
      if (!this.uploadFiles.length) return this.$message.warning('请上传付款单')
      this.loading = true
      postAction('/order/pay', {
        file: this.uploadFiles[0]['url'],
        orderNo: this.orderNo || this.newOrderNo
      }).then(res => {
        const { success, message } = res
        this.loading = false
        if (success) {
          // this.$message.success(message)
          
          postAction('/order/payed', {
            orderNo: this.orderNo
          }).then(res => {
            const { success, message, data } = res
            if (success) {
              // this.$message.success(message)
              this.handleCancel()
              this.handleReloadData(1) // 刷新主列表
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.payInfo = {}
    }
  }
}
</script>

<style lang="less" scoped>
.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}
.ant-form-item-label > label {
  color: #000!important;
}
</style>
