<!--
 * @Description: 上传收货单 - 设计
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:26:43
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-21 11:28:19
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadShippedFile.vue
-->
<template>
  <DrawerView :visible="visible" :width="640" title="上传收货单" @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model :colon="false">
      <a-form-model-item>
        <div class="uploadCover">
          <div style="width: 344px">
            <UploadDrag v-model="uploadFiles" @change="handleChangeFile" uploadText="点击上传收货文件" :number="10" :orderNo="orderNo" />
          </div>
          <div class="endTitle">格式要求：支持上传图片格式、压缩包格式、pdf格式等，单个大小不超过<span class="text">10M</span> ,
最多上传 <span class="text">10</span> 个附件</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from './uploadDrag'
import { postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
export default {
  name: 'uploadShippedFile',
  components: {
    UploadDrag,
    DrawerView
  },
  inject: ['handleReloadData'],
  data() {
    return {
      visible: false,
      loading: false,
      uploadFiles: [],
      orderNo: null
    }
  },
  methods: {
    handleOpen({ orderNo }) {
      this.visible = true
      this.orderNo = orderNo
      console.log(orderNo)
    },
    handleChangeFile(e) {
      this.uploadFiles = e
    },
    handleSubmit() {
      console.log(this.uploadFiles)
    //  if (!this.uploadFiles.length) return this.$message.warning('请上传收货单据文件')
      this.loading = true
      postAction('/order/transport/file/confirm', [
        {
          orderNo: this.orderNo,
          signFiles: this.uploadFiles[0] ? this.uploadFiles.map(i => i.url) : []
        }
      ]).then(res => {
        const { success, message } = res
        if (success) {
          // this.$message.success(message)
          this.handleReloadData(1)
          this.handleCancel()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
    }
  }
}
</script>


<style lang="less" scoped>
.uploadCover {
  width: 100%;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    width: 424px;
    margin-top: -15px;
    text-align: center;
    line-height: 20px;
    // .flexLayout();
    font-size: 12px;
    // justify-content: center;
    color: #8C8C8C;
    margin-top: 8px;
    span.text {
      color: #FF6026;
    }
  }
}
.ant-form-item-label > label {
  color: #000;
}
</style>
