<!--
 * @Description: 订单管理
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:18:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-27 10:47:03
 * @FilePath: /page-sass/src/views/requirement/orderManagement/index.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input v-model="queryParams.orderNo" placeholder="请输入订单编号、订单名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px;" />
        <div class="option-area">
          <a-button @click="handleReload" style="width: 81px;">重置</a-button>
          <a-button type="primary" @click="handleSearch" style="width: 81px;">查询</a-button>
        </div>
      </div>
      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" style="margin-top: 8px;"
        :colon="false">
        <a-form-model-item label="需求类型">
          <checkboxSearch v-model="queryParams.purchaseTypes" dictCode="1043" />
        </a-form-model-item>
        <a-form-model-item label="包装材料">
          <checkboxSearch v-model="queryParams.wrapTypes" dictCode="1507" />
        </a-form-model-item>
        <a-form-model-item label="订单状态">
          <checkboxSearch v-model="orderStatus" :options="statusOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="创建时间">
          <radioGroup v-model="CreateTimeScoped" :options="mainOptions" :needLoad="false" :needAll="false"
            @change="handleChangeRadio($event, 'CreateTimeScoped')">
            <a-radio :value="-1">
              <a-range-picker v-model="CreateTimeScopedRange" value-format="YYYY-MM-DD HH:mm:ss" separator="⇀" showTime
                @change="handleChangeDate($event, 'CreateTimeScoped')" style="width: 310px;height: 26px;">
                <span class="pickerDict">
                    <span v-if="CreateTimeScopedRange.length">{{ CreateTimeScopedRange[0] }} ~ {{ CreateTimeScopedRange[1] }}</span>
                    <span class="placeholder" v-else>请选择</span>
                    <a-icon type="caret-down" style="margin-left: 8px;color:#999"></a-icon>
                  </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <div class="option-area">
        <a-button type="link" @click="handleExport">
          <img src="/export.png" alt="" class="icon">
          <span>导出数据</span>
        </a-button>
      </div>
      <a-table rowKey="orderNo" :columns="columns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :loading="loading"
        :data-source="dataSource" :pagination="false" style="margin-bottom: 16px" :scroll="{ x: 'max-content' }">
        <span class="row_title" slot="orderNo" slot-scope="text, record">
          <span class="orderName">
            {{ text }}
          </span>
          <img src="/new.png" alt="" class="newIcon" v-if="record.newFlag">
        </span>
        <template slot="wrapTypesDictName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <span>{{ text | ellipsis(6) }}</span>
          </a-tooltip>
        </template>
        <template slot="status" slot-scope="text, record">
          <span v-if="text === 0">待签署</span>
          <span v-else-if="text === 1">已签署</span>
          <span v-else-if="text === 2">已预付</span>
          <span v-else-if="text === 3">生产中</span>
          <span v-else-if="text === 4">已发货</span>
          <span v-else-if="text === 5">运输中</span>
          <span v-else-if="text === 6">已收货</span>
          <span v-else-if="text === 7">已完结</span>
          <span v-else-if="text === 9">已付款</span>
          <span v-else-if="text === -1">已取消</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <template v-if="record.newContact && record.status != 0">
            <a @click="handleOPenContractUploader(record)">上传合同</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.status == 0">
            <a @click="handleOPenContractUploader(record)">上传合同</a>
            <a-divider type="vertical" />
          </template>
          <!-- 上传付款单的时候不能是供应商，且必须是已经签署了 -->
          <template v-else-if="record.orderProcess == '1' && record.status == 1 && setUserInfo().tradeIdentity != 0">
            <a @click="handleOpenPayOrder(record.orderNo)">上传预付款单</a>
            <a-divider type="vertical" />
          </template>
          <!-- 上传尾款单 -->
          <!-- record.orderProcess == 2 &&  -->
          <template v-else-if="record.status == 6 && record.orderProcess != '3' && setUserInfo().tradeIdentity != 0">
            <a @click="handleOpenPayedOrder(record.orderNo)">上传付款单</a>
            <a-divider type="vertical" />
          </template>
          <template
            v-else-if="(record.status == 1 && record.orderProcess == '2' || record.status == 2) && setUserInfo().tradeIdentity == 0">
            <a @click="handleOpenEntryProduct(record.orderNo)">进入生产</a>
            <a-divider type="vertical" />
          </template>
          <template v-else-if="record.status == 3 && setUserInfo().tradeIdentity == 0">
            <a @click="handleOpenTransport(record)">上传发货单</a>
            <a-divider type="vertical" />
          </template>
          <template v-else-if="record.status == 4 && setUserInfo().tradeIdentity != 0">
            <a @click="handleOpenUploadShipped(record)">上传收货单</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.status == 9 && record.evaluationFlag == 0">
            <a @click="handleJumpAppraise(record)">评价</a>
            <a-divider type="vertical" />
          </template>
          <a @click="handleSwitchTab(record)">详情</a>
          <template v-if="(record.status == 9  || record.status == 7) && setUserInfo().tradeIdentity != 0">
            <a-divider type="vertical" />
            <a @click="handleEmitOrder(record)">翻单</a>
          </template>
        </template>
      </a-table>
      <ZPagination :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage" />
    </div>
    <!-- 上传合同 -->
    <UploadContract ref="UploadContract" :orderNo="orderNo" :tradeIdentity="setUserInfo().tradeIdentity" />
    <!-- 预付款单 -->
    <UploadPaymentInfo ref="UploadPaymentInfo" :orderNo="orderNo" />
    <!-- 发货单 -->
    <UploadTransportOrder ref="UploadTransportOrder" :orderNo="orderNo" @openUpload="handleReUploadFile" />
    <!-- 发货单上传 -->
    <UploadTransportFile ref="UploadTransportFileRef" :orderNo="orderNo" />
    <!-- 设计发货 -->
    <UploadTransportIsTemplateFile ref="UploadTransportIsTemplateFileRef" :orderNo="orderNo" />
    <!-- 收货单 -->
    <UploadShippedFile ref="UploadShippedFile" :orderNo="orderNo" />
    <!-- 非设计收获 -->
    <uploadShippedFileImportant ref="uploadShippedFileImportant" :orderNo="orderNo" />
    <!-- 已收货 - 上传付款单 -->
    <UploadPayedFile ref="UploadPayedFile" :orderNo="orderNo" />
    <!-- 修改订单 -->
    <EditOrder ref="EditOrder" :orderNo="orderNo" />
    <!-- 进入生产 -->
    <a-modal title="温馨提示" :visible="visible" centered :confirm-loading="confirmLoading" @ok="handleEntryProduct"
      @cancel="handleCancelProduct">
      <div class="info-card">
        <img src="/pass.png" alt="" />
        <span>当前订单已进入生产~</span>
      </div>
    </a-modal>
    <!-- 采购商评价供应商 -->
    <AppraiseSupplier ref="AppraiseSupplier" :orderNo="orderNo" />
    <!-- 翻单 -->
    <a-modal title="温馨提示" :visible="showChangeOrder" centered @ok="handleCreateOrder" @cancel="showChangeOrder = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>是否对当前订单进行翻单?</span>
      </div>
    </a-modal>
  </div>

</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import OrderItem from './modules/orderItem.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import UploadContract from './modules/uploadContract.vue'
import UploadPaymentInfo from './modules/uploadPaymentInfo.vue'
import UploadTransportOrder from './modules/uploadTransportOrder.vue'
import UploadShippedFile from './modules/uploadShippedFile.vue'
import UploadPayedFile from './modules/UploadPayedFile.vue'
import { postAction, downFiles } from '@/api/manage'
import AppraiseSupplier from './modules/appraiseSupplier.vue'
import EditOrder from './modules/editOrder.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import uploadShippedFileImportant from './modules/uploadShippedFileImportant.vue'
import { getDateRange } from '@/util/utils'
import UploadTransportFile from './modules/UploadTransportFile.vue'
import UploadTransportIsTemplateFile from './modules/UploadTransportIsTemplateFile.vue'
export default {
  name: 'orderManagement',
  mixins: [ListMixin],
  components: {
    checkboxSearch,
    InterestedCard,
    OrderItem,
    ZPagination,
    EmptyArea,
    UploadContract,
    UploadPaymentInfo,
    UploadTransportOrder,
    UploadShippedFile,
    UploadPayedFile,
    AppraiseSupplier,
    EditOrder,
    radioGroup,
    uploadShippedFileImportant,
    UploadTransportFile,
    UploadTransportIsTemplateFile
  },
  provide() {
    return {
      handleReloadData: this.handleLoadData,
    }
  },
  data() {
    return {
      selectedRowKeys: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      showChangeOrder: false,
      visible: false,
      confirmLoading: false,
      rangerDate: [],
      queryParams: {
        status: [],
        orderNo: null,
      },
      url: {
        list: '/order/progress',
      },
      CreateTimeScoped: '3',
      orderStatus: '',
      CreateTimeScopedRange: [],
      statusOptions: [
        {
          dictKey: '0',
          dictValue: '待签署'
        },
        {
          dictKey: '1',
          dictValue: '已签署'
        },
        {
          dictKey: '2',
          dictValue: '已预付'
        },
        {
          dictKey: '3',
          dictValue: '生产中'
        },
        {
          dictKey: '4',
          dictValue: '已发货'
        },
        {
          dictKey: '5',
          dictValue: '运输中'
        },
        {
          dictKey: '6',
          dictValue: '已收货'
        },
        {
          dictKey: '7',
          dictValue: '已完结'
        },
        {
          dictKey: '8',
          dictValue: '已付款'
        },
        {
          dictKey: '-1',
          dictValue: '已取消'
        },
      ],
      mainOptions: [
        {
          dictKey: '0',
          dictValue: '当日'
        },
        {
          dictKey: '1',
          dictValue: '近7日'
        },
        {
          dictKey: '2',
          dictValue: '近30天'
        },
        {
          dictKey: '3',
          dictValue: '近90天'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        },
        {
          dictKey: '5',
          dictValue: '近一年'
        }
      ],
      columns: [ // wrapTypesDictName和purchaseTypeDictName
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 236,
          scopedSlots: { customRender: 'orderNo' },
        },
        {
          title: '订单名称',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 200
        },
        {
          title: '需求类型',
          dataIndex: 'purchaseTypeDictName',
          key: 'purchaseTypeDictName',
          width: 120
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypesDictName',
          key: 'wrapTypesDictName',
          scopedSlots: { customRender: 'wrapTypesDictName' },
          width: 160
        },
        {
          title: '订单金额/元',
          dataIndex: 'payables',
          key: 'payables',
          width: 150
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          width: 140
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 240,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      sorter: {
        userId: this.setUserInfo().businessId || this.setUserInfo().userId,
        userType: this.setUserInfo().tradeIdentity,
      },
      requestType: 'post',
      orderNo: null,
      createOrderInfo: null
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    ...mapActions(['changeInfoCount']),
    handleCleanCount() {
      this.changeInfoCount({
        orderCount: 0
      })
    },
    handleReload() {
      this.queryParams = {
        status: [],
        orderNo: null,
      }
      this.CreateTimeScopedRange = []
      this.CreateTimeScoped = '3'
      this.handleLoadData(1)
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = [];
      }
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      const { wrapTypes, orderNo, purchaseTypes } = this.queryParams;
      let pageParams = { ...this.pageNation }, params = { orderNo }, wrapTypesList = [], purchaseTypesList = [];
      delete pageParams.total;
      if (this.orderStatus) {
        params.status = this.orderStatus.split(',');
      }
      if (purchaseTypes) {
        purchaseTypesList = purchaseTypes.split(',');
      }
      if (wrapTypes) {
        wrapTypesList = wrapTypes.split(',');
      }
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1;
          params = { ...params, startDate: `${lastYear}-01-01 00:00:00`, endDate: `${lastYear}-12-31 23:59:59` }
        } else {
          const [startDate, endDate] = getDateRange(this.CreateTimeScoped)
          params = { ...params, startDate, endDate }
        }

      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [startDate, endDate] = this.CreateTimeScopedRange;
        params = { ...params, startDate, endDate }
      }
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : postAction
      api(this.url.list, {
        pagesSize: pageSize,
        purchaseTypes: purchaseTypesList,
        wrapTypes: wrapTypesList,
        pageNum,
        ...params,
        ...this.sorter
      }).then(res => {
        const { success, data } = res;
        this.loading = false
        if (success) {
          const { total, list } = data
          this.dataSource = list
          this.pageNation['total'] = total
        }
      }).catch(e => {
        this.loading = false
      })
    },
    handleSearch() {
      this.handleLoadData(1)
    },
    /* 开启合同上传 */
    handleOPenContractUploader(item) {
      this.orderNo = item.orderNo
      // 开启抽屉
      this.$refs.UploadContract.handleOpen(item)
    },
    /* 打开上传付款单 */
    handleOpenPayOrder(orderNo) {
      this.orderNo = orderNo
      this.$refs.UploadPaymentInfo.handleShowModal(orderNo)
    },
    /* 进入生产 */
    handleOpenEntryProduct(orderNo) {
      this.orderNo = orderNo
      this.visible = true
      this.confirmLoading = false
    },
    /* 进入生产的提示 */
    handleEntryProduct() {
      postAction('/order/producing', {
        orderNo: this.orderNo,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          // // this.$message.success(message)
          this.visible = false
          this.handleLoadData()
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 评价 */
    handleJumpAppraise({ orderNo }) {
      this.$router.push(
        this.setUserInfo().tradeIdentity == 2
          ? `/orderManagement/appraiseForUser?orderNo=${orderNo}`
          : `/orderManagement/appraisePurchaser?orderNo=${orderNo}`
      )
    },
    /* 取消发货提示 */
    handleCancelProduct() {
      this.visible = false
      this.confirmLoading = false
    },
    /* 导出 */
    handleExport() {
      let params = {}
      if (this.orderStatus) {
        params.status = this.orderStatus.split(',');
      }
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1;
          params = { ...params, startDate: `${lastYear}-01-01 00:00:00`, endDate: `${lastYear}-12-31 23:59:59`, ...this.sorter }
        } else {
          const [startDate, endDate] = getDateRange(this.CreateTimeScoped)
          params = { ...params, startDate, endDate, ...this.sorter }
        }

      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [startDate, endDate] = this.CreateTimeScopedRange;
        params = { ...params, startDate, endDate, ...this.sorter }
      } else {
        params = {
          ...this.queryParams, ...this.sorter,
        }
      }
      downFiles('/order/download', params).then((data) => {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.zip')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          let fileName = `订单信息_${params.startDate || ''}_${params.endDate || 0}.xlsx`
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    /* 开启发货单 */
    handleOpenTransport({ orderNo, createTime, newDelivery, transportsVo, newFill2, purchaseType }) {
      this.orderNo = orderNo
      // UploadTransportIsTemplateFileRef
      if (purchaseType == '1043001') { // 设计
        this.$refs.UploadTransportIsTemplateFileRef.handleOpen({ orderNo, transportsVo, purchaseType })
      } else { // 非设计
        if (newDelivery == true && newFill2 == false) { // 填写收货单
          this.$refs.UploadTransportOrder.handleEdit({ createTime, orderNo, transportsVo, newFill2, purchaseType })
        } else if (newDelivery == true && newFill2 == true) { // 上传发货单
          this.$refs.UploadTransportFileRef.handleOpen({ orderNo, transportsVo, purchaseType })
        }
      }
    },
    handleReUploadFile(e) {
      this.$refs.UploadTransportFileRef.handleOpen(e)
    },
    /* 收货单上传 */
    handleOpenUploadShipped(item) {
      this.orderNo = item.orderNo
      if (item.purchaseType == '1043001') {
        this.$refs.UploadShippedFile.handleOpen({ ...item })
      } else {
        this.$refs.uploadShippedFileImportant.handleOpen({ ...item })
      }
    },
    /* 上传预付款 */
    handleOpenPayedOrder(orderNo) {
      this.orderNo = orderNo
      this.$refs.UploadPayedFile.handleOpen(orderNo)
    },
    handleSwitchTab(record) {
      const { orderTitle, orderNo, purchaseId, status } = record
      console.log(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1)
      this.$router.push(
        this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1
          ? `/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}&purchaseId=${purchaseId}&&hasEdit=${false}`
          : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}&purchaseId=${purchaseId}&hasEdit=${status == 0}`
      )
    },
    handleEmitOrder(item) {
      this.createOrderInfo = { ...item }
      this.showChangeOrder = true
    },
    /* 订单修改 */
    handleEditOrder(item) {
      this.$refs.EditOrder.handleInitData(item)
    },
    /* 翻单 */
    handleCreateOrder() {
      this.showChangeOrder = false;
      // 路由跳转
      this.$router.push({
        path: '/ChangeOrder',
        query: {
          formType: '',
          purchaseId: this.createOrderInfo.purchaseId || '',
          orderNo: this.createOrderInfo.orderNo || '',
          stepKey: 'CompleteInformation',
          copyData: '1'
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .ant-table-column-title {
  font-size: 16px;
}

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    &:not(:last-of-type)>td {
      border: none;
    }

    &>td {
      border-bottom: 1px solid #EFEFEF;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;

}

.searchInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    .ant-btn {
      width: 97px;
      height: 38px;

      &+.ant-btn {
        margin-left: 8px;
      }
    }
  }
}

.option-area {
  margin-bottom: 15px;
  text-align: right;

  .ant-btn-link {
    color: #FF6026;
    font-weight: 400;
  }

  .icon {
    width: 20px;
    margin-right: 2px;
    margin-top: -2px;
  }
}

@import '~@/styles/list.less';

img.newIcon {
  width: 16px;
  margin-left: 6px;
}
.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  .placeholder {
    color: #999;
  }
}
.card-info-box {
  ::v-deep .ant-form-item-label {
    width: 67px!important;
  }
}

.row_title {
  display: flex;
  align-items: center;
}
.card-info-box {
  ::v-deep .ant-badge-dot {
    right: -6px;
  }  
}
</style>
