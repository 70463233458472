<!--
 * @Description: 上excel
 * @Author: zhang zhen
 * @Date: 2023-08-16 21:56:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-21 11:23:29
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadTemplate.vue
-->
<template>
  <div class="uploadView">
    <div class="uploadView-title" style="padding-bottom: 20px;padding-top: 22px;">
      请按照模板文件填写单据，并上传文件
      <a @click="handleGetTemplate" v-show="needDowLoad">
        <div style="display: flex;font-size: 14px; justify-content: center;align-items: center;margin-top: 15px;">
          <img src="/download_icon.png" alt class="arrow" slot="suffixIcon" width="20px" />
          <span>下载收货单据模板</span>
        </div>
      </a>
    </div>

    <a-upload name="file" :multiple="false" :action="uploadAction" :disabled="disabled" :headers="headers" :fileList="fileList"
      @change="handleChangeImage" :before-upload="beforeUpload">
      <a-button type="primary" style="width: 248px;height: 40px;font-size: 16px;">
       <img src="/upload_top.png" alt="" class="icon">上传收货单据
      </a-button>
    </a-upload>
    <p style="color:#8C8C8C;margin-top: 10px;font-size: 12px;line-height: 20px;text-align: center;">单据下载完成后，请打印并填写单据上的 <span style="color: #FF6026;">收货人签字</span>、<span style="color: #FF6026;">收货日期</span> , 单据填写完成后，请电子扫描并上传已填写的发货单据</p>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'excelUploadFile',
  props: {
    needDowLoad: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      templateParams: null,
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  methods: {
    handleInit(item) {
      this.templateParams = item;
    },
    handleGetTemplate(item) {
      getAction('/order/download/getTransportExport', { orderNo: this.orderNo }).then(data => {
        if (data.code && data.code == 500) {
          this.$message.warning(data.message)
        } else {
          // 下载
          window.open(`/api/order/download/getTransportExport?orderNo=${this.orderNo}`)
        }
        
      })
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isLimit = file.size / 1024 / 1024 <= 10;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 10MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
    handleChangeImage({ file, fileList }) {
      let fileLists = fileList
      const that = this
      console.log('file.response', file.response, file.status)
      if (file.status === 'done') {
        fileLists = fileLists.slice(-1)
        if (file.response) {
          if (file.response.success) {
            // if (!file.response.data.result) {
            //   return that.$confirm({
            //     title: '文件校验不通过',
            //     content: '下载重新上传！',
            //     okText: '确认',
            //     cancelText: '取消',
            //     onOk() {
            //       window.open(file.response.data.url)
            //     },
            //     onCancel() { }
            //   })
            // }
            this.$emit('uploadFile', { fileUrl: file.response.data.url, fileId: '' })
          } else {
            this.$message.warning(file.response.message)
          }
        } else {
          // console.log(file.response.data)
          // that.$confirm({
          //   title: '文件校验不通过',
          //   content: '下载重新上传！',
          //   okText: '确认',
          //   cancelText: '取消',
          //   onOk() {
          //     window.open(file.response.data.url)
          //   },
          //   onCancel() { }
          // })
        }
      } else if (file.status === 'error') {
        this.$message.warning(file.response.message)
      }
      console.log(fileLists)
      this.fileList = fileLists.filter(i => i.status != 'error')
      this.$emit('change', this.fileList.map(file => ({ fileUrl: file.response ? file.response.data.url : '', fileId: '' })));

    }
  }
}
</script>

<style lang="less" scoped>
.uploadView {
  width: 584px;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  &-title {
    width: 100%;
    padding-bottom: 40px;
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px;
  }
}

.icon {
  width: 20px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>
