<!--
 * @Description: 订单列表 - 上传合同弹出
 * @Author: zhang zhen
 * @Date: 2023-05-30 09:29:17
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-11-15 21:24:58
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadContract.vue
-->
<template>
  <DrawerView :visible="visible" :width="800" title="合同上传" @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model :colon="false">
      <a-form-model-item label="上传合同" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label-align="left" required>
        <div class="uploadCover">
          <div style="width: 334px">
            <UploadDrag ref="UploadDragRef" v-model="uploadFiles" :isUpload="isUpload" @change="handleChangeFile"
              :number="1" />
          </div>
          <div class="endTitle">可支持多种文件格式，如PDF，XLS、PNG、JPG等</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <a-card :title="tradeIdentity == 0 ? '采购商上传合同' : '供应商上传合同'">
      <FileListView ref="FileListViewRef" />
    </a-card>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from '@/components/plugins/uploadDragNew'
import { postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import FileListView from './children/fileListView.vue'
export default {
  name: 'uploadContract',
  components: {
    UploadDrag,
    DrawerView,
    FileListView
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    tradeIdentity: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      isUpload: false,
      uploadFiles: [],
    }
  },
  methods: {
    handleOpen(item) {
      this.visible = true
      const { contractFileCustomer, contractFileSupplier } = item;
      if (this.tradeIdentity == 0) { // 供应商
        this.isUpload = !!contractFileSupplier
        this.isUpload && this.handleSetFileUrl(contractFileSupplier)
        contractFileCustomer && this.handleViewFile(contractFileCustomer)
      } else {
        this.isUpload = !!contractFileCustomer
        this.isUpload && this.handleSetFileUrl(contractFileCustomer)
        contractFileSupplier && this.handleViewFile(contractFileSupplier)
      }
    },
    handleViewFile(file) {
      this.$nextTick(_ => {
        this.$refs.FileListViewRef.handleGetFileList([file])
      })
    },
    handleSetFileUrl(url) {
      this.$nextTick(_ => {
        this.$refs.UploadDragRef.initFileList([{
          url,
          srcFileName: url.split('/images/')['1']
        }])
      })
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleSubmit() {
      if (!this.uploadFiles.length)
        return this.$message.warning({
          content: h => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请上传合同文件</span>,
          icon: h => <img src="./noPass.png" width="21" height="21" />
        })
      this.loading = true
      postAction('/order/contract', {
        contractFile: this.uploadFiles[0]['url'],
        orderNo: this.orderNo
      }).then(res => {
        const { success, message } = res
        this.loading = false
        if (success) {
          // this.$message.success(message)
          this.handleCancel()
          this.handleReloadData(1)
          // postAction('/order/sign', {
          //   orderNo: this.orderNo
          // }).then(res => {
          //   const { success, message, data } = res
          //   if (success) {
          //     // this.$message.success(message)
          //     this.handleCancel()
          //     this.handleReloadData(1)
          //   } else {
          //     this.$message.warning(message)
          //   }
          // })
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
    }
  }
}
</script>

<style lang="less" scoped>
.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.ant-form-item-label>label {
  color: #000;
}</style>
