<!--
 * @Description: 填写收货单
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-27 15:14:45
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadTransportOrder.vue
-->
<template>
  <DrawerView :visible="visible" :width="760" title="填写发货单" @confirm="handleOk" @cancel="handleCancel">
    <a-tabs v-model="activePart" tab-position="left" class="step_form" @change="handleChangeTab">
      <a-tab-pane v-for="(item, index) in transportsVo" :key="index">
        <template #tab>
          <img src="/to_input.png" alt="" class="to_input" v-if="!item.newFill">
          <img src="/input_finished.png" alt="" class="to_input" v-else>
          <span class="label-span" style="margin-right: 15px;">{{ item.tabName }}</span>
        </template>
        <a-form-model
          class="info-form"
          :model="item"
          :label-col="{
            span: 4
          }"
          :wrapper-col="{
            span: 19
          }"
          :ref="`infoForm_${index}`"
          :colon="false"
          :rules="rules"
        >
        <a-form-model-item label="发货方式" prop="transportMode">
            <j-dictSelect v-model="item.transportMode" style="width: 380px" placeholder="请选择" dictCode="1018" />
          </a-form-model-item>
          <a-form-model-item label="运单号" prop="waybill" v-if="item.transportMode != '1018002'">
            <a-input placeholder="请输入" v-model="item.waybill" style="width: 380px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="发货时间" prop="deliveryDate">
            <JDate placeholder="请选择" style="width: 380px" v-model="item.deliveryDate" :passDate="lastDate" :removePast="true" />
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea
              placeholder="请输入"
              allow-clear
              v-model="item.description"
              style="width: 380px"
              :autosize="{ minRows: 6, maxRows: 6 }"
            />
          </a-form-model-item>

          <div style="padding-left: 15px;">
            <splitLine label="收货信息" />
          </div>
          <a-form-model-item label="期望收货时间">
            <j-date placeholder="请输入" v-model="item.expectDate" style="width: 380px" disabled />
          </a-form-model-item>
          <a-form-model-item label="收货人">
            <a-input placeholder="请输入" v-model="item.deliveryName" style="width: 380px" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="手机号">
            <a-input placeholder="请输入" v-model="item.phone" style="width: 380px" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="收货地址">
            <a-row :gutter="6" style="width: 380px">
              <a-col :span="8">
                <a-select placeholder="省" v-model="item.deliveryAddress" @change="changeInfo" disabled>
                  <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
                  <a-select-option :value="i.regionId" v-for="i in regionList" :key="i.regionId">{{
                    i.regionName
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <a-select placeholder="市" v-model="item.deliveryCity" @change="handleLoadCity('deliveryDistrict')" disabled>
                  <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
                  <a-select-option :value="i.regionId" v-for="i in deliveryCityList" :key="i.regionId">{{
                    i.regionName
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <a-select placeholder="区" v-model="item.deliveryDistrict" @change="handleUpdateView" disabled>
                  <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
                  <a-select-option :value="i.regionId" v-for="i in deliveryDistrictList" :key="i.regionId">{{
                    i.regionName
                  }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="详细地址">
            <a-input placeholder="请输入" class="lastBtn" v-model="item.detailAddress" style="width: 380px;" :maxLength="20" disabled>
              <div class="NumberInfo" slot="suffix">{{ item.detailAddress && item.detailAddress.length || 0 }}/20</div>
            </a-input>
          </a-form-model-item>
        
          
          <!-- <a-form-model-item label="发货单">
            <div class="uploadCover">
              <div style="width: 334px">
                <UploadDrag v-model="uploadFiles" @change="handleChangeFile" :number="10" />
              </div>
              <div class="endTitle">可支持多种文件格式，如PDF，XLS、PNG、JPG等</div>
            </div>
          </a-form-model-item> -->
        </a-form-model>
      </a-tab-pane>
    </a-tabs>
    
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleOk" type="primary" :loading="confirmLoading">保存</a-button>
      <a-button @click="handleCreateShipOrder" type="primary" :loading="confirmLoading" class="green_btn" :disabled="!newFill2">生成发货单</a-button>
    </template>
  </DrawerView>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import JDate from '@/components/easyComponents/JDate.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
export default {
  name: 'uploadTransportOrder',
  components: {
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect,
    splitLine
  },
  inject: ['handleReloadData'],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      activePart: 0,
      form: {
        detailAddress: ''
      },
      rules: {
        deliveryAddress: { required: true, message: '请选择收货地址' },
        detailAddress: { required: true, message: '请选择详细地址' },
        transportMode: { required: true, message: '请选择发货方式' },
        waybill: { required: true, message: '请输入运单号' },
        insuredAmount: { required: true, message: '请输入保价金' },
        freight: { required: true, message: '请输入运费' },
        deliveryDate: { required: true, message: '请选择发货时间' }
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
      newFill2: false,
      isAllUploadALl: false,
      transportsVo: [],
      purchaseType: null,
    }
  },
  methods: {
    handleEdit({ createTime, orderNo, transportsVo, newFill2, purchaseType }) {
      this.purchaseType = purchaseType || ''
      this.loadRegionList()
      this.lastDate = createTime
      this.visible = true
      this.orderNo = orderNo
      this.newFill2 = newFill2 || false;
      this.transportsVo = transportsVo && transportsVo.map(i => {
        return {
          ...i,
          transportMode: i.transportMode && i.transportMode + '' || undefined,
          orderNo: orderNo,
          invoiceFiles: [],
          freight: 0
        }
      }) || [];
      this.handleChangeTab(0)
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    changeInfo(e) {
      // this.transportsVo[this.activePart]..deliveryAddress = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleChangeTab(e) {
      if (this.transportsVo && this.transportsVo[e]) {
        const { deliveryCity, deliveryAddress } = this.transportsVo[e];
        if (deliveryAddress) {
          getAction('/area/queryByParentIdList', {
            parentId: deliveryAddress
          }).then(res => {
            const { success, data } = res
            if (success) {
              this[`deliveryCityList`] = data
            }
          })
        }
        if (deliveryCity) {
          getAction('/area/queryByParentIdList', {
            parentId: deliveryCity
          }).then(res => {
            const { success, data } = res
            if (success) {
              this[`deliveryDistrictList`] = data
            }
          })
        }
      }
    },
    /* 生成发货单 */
    handleCreateShipOrder() {
      // window.open('/api/order/download/getTransportExport?orderNo=' + this.orderNo)
      this.handleCancel(true);
    },
    handleOk() {
      this.$refs[`infoForm_${this.activePart}`][0].validate(valid => {
        if (valid) {
          let dataList = [...this.transportsVo];
          dataList[this.activePart]['newFill'] = true
          this.confirmLoading = true
          postAction('/order/transport', this.transportsVo).then(res => {
            const { success, message } = res
            this.confirmLoading = false
            if (success) {
              this.transportsVo[this.activePart]['newFill'] = true
              this.handleReloadData(1) // 刷新主列表
              this.newFill2 = this.transportsVo.every(i => i.newFill);
            } else {
              this.$message.warning(message)
            }
          }).catch(e => {
            this.confirmLoading = false
          })
        }
      })
    },
     //     this.handleReloadData(1) // 刷新主列表
              //     this.handleCancel()
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then(res => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.deliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.transportsVo[this.activePart].deliveryCity = undefined
        this.transportsVo[this.activePart].deliveryDistrict = undefined
      }
      this.handleLoadCity('deliveryCity', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'deliveryCity') {
        this.deliveryCityList = []
        this.deliveryDistrictList = []
        !flag && (this.transportsVo[this.activePart].deliveryCity = undefined)
        parentId = this.transportsVo[this.activePart].deliveryAddress
      }
      if (key == 'deliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.transportsVo[this.activePart].deliveryDistrict = undefined)
        parentId = this.transportsVo[this.activePart].deliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleCancel(needNew = false) {
      this.form = {
        detailAddress: ''
      }
      needNew && this.$emit('openUpload', { orderNo: this.orderNo, transportsVo: this.transportsVo,  purchaseType: this.purchaseType });
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
      this.activePart = 0
      this.transportsVo = []
    },

    handleInitData(targetOption) {
      const { deliveryAddress, regionName } = targetOption
      targetOption.loading = true
      getAction('/area/queryByParentIdList', {
        parentId: deliveryAddress
      }).then(res => {
        const { success, data } = res
        if (success) {
          targetOption.loading = false
          targetOption.children = data.map(item => ({ ...item, isLeaf: false }))
          this.$forceUpdate()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}
.NumberInfo {
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-item-label > label {
  color: #000;
}

.info-form {
  ::v-deep .ant-form-item-label {
    width: 96px!important;
  }
}
.step_form {
  ::v-deep .ant-tabs-content {
    padding-left: 0;
  }
}
::v-deep .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
.green_btn:not([disabled]) {
  background-color: #05C29C;
  border-color: #05C29C;
}
span.label-span {
  display: inline-block;
  width: 90px;
  text-align: left;
}
img.to_input {
  width: 52px;
  margin-right: 15px;
}

::v-deep .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.lastBtn {
  ::v-deep .ant-input {
    padding-right: 56px;
  }
}
</style>
